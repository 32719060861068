import React from 'react';
import type { FC } from 'react';
import { Box, IconButton, SvgIcon, Typography, Drawer, Container, Card, CardContent, Divider, Button, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    ChevronLeft as ArrowLeftIcon,
    MapPin as MapPinIcon,
    Phone as PhoneIcon,
    Mail as MailIcon,
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    Linkedin as LinkedinIcon,
    Instagram as InstagramIcon
} from 'react-feather';
import { ChannelView } from 'src/types/channelView';
import MobileInformationRow from './MobileInformationRow';
import MobileInformationLink from './MobileInformationLink';
import { Channel } from 'src/types/channel';
import { useIntl } from 'react-intl';

interface MobileInformationDrawerProps {
    isOpen?: boolean;
    onClose?: () => void;
    channelView: ChannelView;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    drawer: {
        width: '100%',
        zIndex: theme.zIndex.drawer,
        backgroundColor: theme.palette.background.dark
    },
    arrowLeft: {
        paddingLeft: 0
    },
    infoRow: {
        color: theme.palette.text.secondary
    }

}));

const MobileInformationDrawer: FC<MobileInformationDrawerProps> = ({
    isOpen,
    onClose,
    channelView,
}) => {
    const classes = useStyles();
    const intl = useIntl();

    const closeDrawer = (): void => {
        onClose()
    }

    if (channelView.channelViewInfo) {
        return (
            <Drawer
                classes={{ paper: classes.drawer }}
                anchor="left"
                open={isOpen}>
                <Container>
                    <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                        <IconButton className={classes.arrowLeft} onClick={closeDrawer}>
                            <SvgIcon >
                                <ArrowLeftIcon />
                            </SvgIcon>
                        </IconButton>
                        <Typography
                            variant="body2"
                            color='textSecondary'>
                            {channelView.channel_name}
                        </Typography>
                    </Box>
                    <Box mt={3}>
                        <Typography
                            variant="h3"
                            color='textPrimary'>
                            {intl.formatMessage({ id: 'channel.PublicChannelDetailView.mobile.MobileInformationDrawer.information' })}
                        </Typography>
                    </Box>
                    <Box mt={2} display="flex" flexDirection="column" gap={2}>
                        <Card>
                            <Box padding={2}>
                                <Typography
                                    variant="h6"
                                    color='textPrimary'>
                                    {intl.formatMessage({ id: 'channel.PublicChannelDetailView.mobile.MobileInformationDrawer.description' })}
                                </Typography>
                            </Box>
                            <Divider></Divider>
                            <Box padding={2}>
                                <Typography
                                    variant="body2"
                                    color='textPrimary'>
                                    {channelView.channelViewInfo.channel_view_description}
                                </Typography>

                            </Box>
                            <Box padding={2}>
                                <Typography
                                    variant="body2"
                                    color='textPrimary'>
                                    {channelView.channel_name}
                                </Typography>
                            </Box>
                        </Card>
                        {
                            channelView.channelViewInfo.channelViewInfoLinkList.length > 0
                            &&
                            <Card sx={{ marginBottom: 2 }}>
                                <Divider></Divider>
                                <Box padding={2}>
                                    <Typography
                                        variant="h6"
                                        color='textPrimary'>
                                        {intl.formatMessage({ id: 'channel.PublicChannelDetailView.mobile.MobileInformationDrawer.links' })}
                                    </Typography>
                                </Box>
                                <Divider></Divider>
                                <Box padding={2}>
                                    <Box display="flex" flexDirection="column" gap={2} justifyContent="flex-start">
                                        {channelView.channelViewInfo.channelViewInfoLinkList.map((link, index) => {
                                            return (
                                                <Box key={index}>
                                                    {
                                                        link.text &&
                                                        <Link style={{ textDecoration: 'none' }}
                                                            href={link.link}
                                                            variant="body2"
                                                        >
                                                            {link && link.text}
                                                        </Link>
                                                    }
                                                    {
                                                        !link.text &&
                                                        <Link style={{ textDecoration: 'none' }}
                                                            href={link.link}
                                                            variant="body2"
                                                        >
                                                            {link && link.link}
                                                        </Link>
                                                    }
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            </Card>

                        }

                        <Card sx={{ marginBottom: 2 }}>
                            <Divider></Divider>
                            <Box padding={2}>
                                <Typography
                                    variant="h6"
                                    color='textPrimary'>
                                    {intl.formatMessage({ id: 'channel.PublicChannelDetailView.mobile.MobileInformationDrawer.contact' })}
                                </Typography>
                            </Box>
                            <Divider></Divider>
                            <Box padding={2}>
                                <Box display="flex" flexDirection="column" gap={2} justifyContent="flex-start" className={classes.infoRow}>
                                    {channelView.channelViewInfo.direction && <MobileInformationRow icon={<MapPinIcon />} text={channelView.channelViewInfo.direction}></MobileInformationRow>}
                                    {channelView.channelViewInfo.phone_number_one && <MobileInformationRow icon={<PhoneIcon />} text={channelView.channelViewInfo.phone_number_one.toString()}></MobileInformationRow>}
                                    {channelView.channelViewInfo.phone_number_two && <MobileInformationRow icon={<PhoneIcon />} text={channelView.channelViewInfo.phone_number_two.toString()}></MobileInformationRow>}
                                    {channelView.channelViewInfo.email && <MobileInformationRow icon={<MailIcon />} text={channelView.channelViewInfo.email}></MobileInformationRow>}
                                </Box>
                            </Box>
                            <Divider></Divider>
                            <Box padding={2} mb={6}>
                                <Box display="flex" flexDirection="column" gap={2} justifyContent="flex-start" className={classes.infoRow}>
                                    {channelView.channelViewInfo.facebook_account && <MobileInformationLink icon={<FacebookIcon />} text="Facebook" link={channelView.channelViewInfo.facebook_account} />}
                                    {channelView.channelViewInfo.linkedin_account && <MobileInformationLink icon={<LinkedinIcon />} text="Linkedin" link={channelView.channelViewInfo.linkedin_account} />}
                                    {channelView.channelViewInfo.instagram_account && <MobileInformationLink icon={<InstagramIcon />} text="Instagram" link={channelView.channelViewInfo.instagram_account} />}
                                    {channelView.channelViewInfo.twitter_account && <MobileInformationLink icon={<TwitterIcon />} text="Twitter" link={channelView.channelViewInfo.twitter_account} />}
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Container>
            </Drawer>
        );
    } else return null
};

export default MobileInformationDrawer;