import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Chip, Divider, Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Box as BoxIcon,
    Lock as LockIcon,
    File as FileIcon,
    Share2 as ShareIcon,
} from 'react-feather';
import { Model } from 'src/types/model';
import { Channel } from 'src/types/channel';
import ModelBlockModalSuscribe from './ModelBlockModalSuscribe';
import { FileResource } from 'src/types/file_resource';
import { Label } from '@mui/icons-material';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';
import { useHistory } from 'react-router-dom';

interface FileBoxProps {
    file: FileResource;
    mode: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    boxIcon: {
        position: 'relative',
        color: theme.palette.text.secondary,
        float: 'left',
    },
    fileText: {
        overflow: 'hidden',
    },
    titleFile: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '0.5rem',
        height: '2.5rem',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    chip: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: 'white',
        cursor: 'pointer',
    },
    boxFileIcon: {
        position: 'relative',
        display: "flex",
        backgroundColor: theme.palette.neutral[400],
        aspectRatio: '16 / 9',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.25rem",
        cursor: 'pointer',
        width: '100%',
        height: 'auto',
    },
    fileIcon: {
        width: '5rem',
        height: '5rem',
        color: theme.palette.primary.contrastText,
    },
    description: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '2rem',
    },
    image: {
        width: '100%',
        height: 'auto',
        aspectRatio: '16 / 9',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.dark,
    },
}));

const FileBox: FC<FileBoxProps> = ({ file, mode }) => {
    const classes = useStyles();

    const config = getWSConfig();

    const getFileLink = (): void => {
        let params = {}
        params["file_id"] = file.file_id;
        axios.post(process.env.REACT_APP_SERVER_URL + '/file_resource/1.0/get/file/link/public', params, config)
            .then(function (response) {
                if (response && response.data) {
                    window.open(response.data);
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            });
    };

    const openFileLink = (): void => {
        getFileLink();
    };

    const [thumbnailFileLink, setThumbnailFileLink] = useState(null);
    const getThumbnailFileLink = (): void => {
        let params = {}
        params["file_id"] = file.file_id;
        axios.post(process.env.REACT_APP_SERVER_URL + '/file_resource/1.0/get/thumbnail_link', params, config)
            .then(function (response) {
                if (response && response.data) {
                    setThumbnailFileLink(response.data);
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
                return null;
            });
    };
    useEffect(() => {
        if (file.thumbnail_extension) getThumbnailFileLink();
    }, []);

    return (
        <Grid
            item
            key={file.file_id}
            xs={mode === 'grid' ? 12 : 12}
            sm={mode === 'grid' ? 12 : 6}
            md={mode === 'grid' ? 6 : 4}
            lg={mode === 'grid' ? 4 : 3}
            xl={mode === 'grid' ? 3 : 3}
        >
            <Card
                className={classes.root}
                raised={false}
                onClick={() => openFileLink()}
            >
                <Box
                    p={2}
                    paddingBottom={3}
                >
                    {(file.thumbnail_extension) && <CardMedia className={classes.image}
                        image={thumbnailFileLink}
                    >
                    </CardMedia>}
                    {(!file.thumbnail_extension) && (file.thumbnail_url) && <CardMedia className={classes.image}
                        image={file.thumbnail_url}
                    >
                    </CardMedia>}
                    {(!file.thumbnail_extension) && (!file.thumbnail_url) && <Box className={classes.boxFileIcon}>
                        <FileIcon className={classes.fileIcon} />
                        <Chip
                            label={file.extension}
                            color='primary'
                            className={classes.chip}
                            size='small'
                        />
                    </Box>}
                    <Box
                        display={"flex"}
                    >
                        <Box p={1.5} pl={0}>
                            <SvgIcon
                                fontSize="small"
                                className={classes.boxIcon}
                            >
                                <FileIcon />
                            </SvgIcon>
                        </Box>
                        <Box pt={1.5}>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                                className={classes.fileText}
                            >
                                {"File"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            className={classes.titleFile}
                        >
                            {file.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            className={classes.description}
                        >
                            {file.description}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
            </Card>
        </Grid>
    );

};

export default FileBox;
