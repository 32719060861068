import React, { useRef, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import {
    Box,
    Card,
    Input,
    Typography,
    Checkbox,
    CardActions,
    Button,
    TextField,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
    Chip,
    ListItemText,
    FormControl,
    Select,
    OutlinedInput,
    InputLabel,
    Stack,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Search as SearchIcon,
    Grid as AllContentIcon,
    CheckCircle as EconsentIcon,
    Box as ModelIcon,
    Video as VideoIcon,
    Filter as FilterIcon,
    File as FileIcon,
    ExternalLink as LinkIcon
} from 'react-feather';

import { ChannelViewTab } from 'src/types/channelView';
import { Channel } from 'src/types/channel';
import { Autocomplete } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchBarMenu from './SearchBarMenu';

interface PublicSearchBarProps {
    setSelectAll: any;
    setVideoChecked: any;
    setModelsChecked: any;
    query: string;
    setQuery: any;
    languageFilter: number;
    setLanguageFilter: any;
    setFilesChecked: any;
    setExternalLinksChecked: any;
    currentTab: any;
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minHeight: 64,
    },
    queryField: {
        width: '100%',
        marginLeft: theme.spacing(2)
    },
    checkboxVideos: {
        color: theme.palette.primary.main + "!important",
    },
    checkboxModels: {
        color: theme.palette.primary.main + "!important",
    },
    VideoText: {
        marginRight: '2rem',
    },
    ModelText: {
        marginRight: '2rem',
    },
    ShowMe: {
        marginRight: '2rem',
        marginLeft: '0rem',
    },
    BoxCheckBox: {
        marginRight: '2rem',
    },
    buttonMenu: {
        color: theme.palette.text.primary,
    },
    buttonMenuRight: {
        color: theme.palette.text.primary,
    },
    IconOnButton: {
        marginRight: "0.5rem",
    },
    MenuStyle: {
        transform: "Translate(-0.5%,4.5%)",
        paddingBottom: 0,
        '& .MuiList-padding': {
            minWidth: "20.5rem",
        },
    },
    cardFilter: {
        paddingTop: "1rem",
        paddingLeft: "1rem",
        paddingBottom: "1.5rem",
        paddingRight: "1rem",
    },
    menuFilter: {
        transform: "Translate(-1.5%,6.5%)",
        '& .MuiList-padding': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    MenuItemStyle: {
        paddingBottom: "1rem",
        paddingTop: "1rem",
    },
    listItemIcon: {
        minWidth: "0rem",
        alignItems: "center",
        paddingRight: "1rem"
    },
    icon: {
        color: theme.palette.text.secondary
    }
}));

const PublicSearchBar: FC<PublicSearchBarProps> = ({
    languageFilter,
    setLanguageFilter,
    query,
    setQuery,
    setSelectAll,
    setModelsChecked,
    setVideoChecked,
    setFilesChecked,
    setExternalLinksChecked,
    currentTab
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const [openActionsMenu, setOpenActionsMenu] = useState<boolean>(false);
    const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false);
    const actionsRef = useRef<HTMLButtonElement | null>(null);
    const filterRef = useRef<HTMLButtonElement | null>(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState<number>(0)

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setSelectAll(true);
        setVideoChecked(true);
        setModelsChecked(true);
        setSelectedMenuItem(0)
        event.persist();
        setQuery(event.target.value);
    };

    const handleOpenActionsMenu = (): void => {
        setOpenActionsMenu(true);
    };

    const handleOpenFilterMenu = (): void => {
        setOpenFilterMenu(true);
    };

    const handleCloseActionsMenu = (): void => {
        setOpenActionsMenu(false);
    };
    const handleCloseFilterMenu = (): void => {
        setSelectAll(true);
        setVideoChecked(true);
        setModelsChecked(true);
        setSelectedMenuItem(0)
        setOpenFilterMenu(false);
    };

    const handleViewAllContent = (): void => {
        setSelectedMenuItem(0)
        handleCloseActionsMenu();
        setVideoChecked(true);
        setModelsChecked(true);
    };

    const handleViewVideos = (): void => {
        setSelectedMenuItem(1)
        handleCloseActionsMenu();
        setVideoChecked(true);
        setModelsChecked(false);
    };

    const handleViewModels = (): void => {
        setSelectedMenuItem(2)
        handleCloseActionsMenu();
        setVideoChecked(false);
        setModelsChecked(true);
    };

    const handleLanguageFilterDelete = (): void => {
        setLanguageFilter(0);
    };
    const languages = [
        { label: intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.all' }), id: 0 },
        { label: intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.spanish' }), id: 1 },
        { label: intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.english' }), id: 2 },
        { label: intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.catalan' }), id: 3 }
    ];

    const getMenuItemString = (item: number): string => {
        if (item == 0) return intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.allcontent' })
        else if (item == 1) return intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.videos' })
        else if (item == 2) return intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.models3d' })
        else if (item == 3) return intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.files' })
        else return intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.external_links' })
    }

    const getLanguageString = (item: number): string => {
        if (item == 0) return intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.all' })
        else if (item == 1) return intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.spanish' })
        else if (item == 2) return intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.english' })
        else return intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.catalan' })
    }

    const changeLanugage = (event) => {
        setLanguageFilter(event.target.value)
    }

    return (
        <div style={{ width: '100%', marginTop: '2rem' }}>
            <Box>
                <Card
                    className={classes.root}
                >
                    <CardActions className={classes.root} >
                        <Box>
                            <Button
                                onClick={handleOpenActionsMenu}
                                ref={actionsRef}
                                size="medium"
                                variant="text"
                                className={classes.buttonMenu}
                            >
                                <Box display="flex">
                                    {selectedMenuItem === 0 &&
                                        <ListItemIcon className={classes.listItemIcon}><AllContentIcon className={classes.icon} /></ListItemIcon>}
                                    {selectedMenuItem === 1 &&
                                        <ListItemIcon className={classes.listItemIcon}><VideoIcon className={classes.icon} /></ListItemIcon>}
                                    {selectedMenuItem === 2 &&
                                        <ListItemIcon className={classes.listItemIcon}><ModelIcon className={classes.icon} /></ListItemIcon>}
                                    {selectedMenuItem === 3 &&
                                        <ListItemIcon className={classes.listItemIcon}><FileIcon className={classes.icon} /></ListItemIcon>}
                                    {selectedMenuItem === 4 &&
                                        <ListItemIcon className={classes.listItemIcon}><LinkIcon className={classes.icon} /></ListItemIcon>}
                                    <ListItemText className={classes.listItemIcon}></ListItemText>
                                    <ListItemText className={classes.listItemIcon}>
                                        {getMenuItemString(selectedMenuItem)}
                                    </ListItemText>
                                    <Box alignItems={"center"} display={"flex"}>
                                        <ArrowDropDownIcon />
                                    </Box>
                                </Box>
                            </Button>
                        </Box>
                        <SearchBarMenu
                            setVideoChecked={setVideoChecked}
                            setModelsChecked={setModelsChecked}
                            setFilesChecked={setFilesChecked}
                            setExternalLinksChecked={setExternalLinksChecked}
                            setOpenActionsMenu={setOpenActionsMenu}
                            setSelectedMenuItem={setSelectedMenuItem}
                            actionsRef={actionsRef}
                            openActionsMenu={openActionsMenu}
                        />
                        <Divider orientation="vertical" flexItem />
                        <Box
                            ml={2}
                            display="flex"
                            alignItems="center"
                            width="100%"
                            p={0}
                        >
                            <SearchIcon />
                            <Input
                                disableUnderline
                                fullWidth
                                className={classes.queryField}
                                onChange={(handleQueryChange)}
                                placeholder={intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.search_in' }) + " " + currentTab.name}
                                value={query}
                            />
                        </Box>
                        <Box
                            p={2}
                            display="flex"
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            {languageFilter !== null && languageFilter !== undefined && languageFilter !== 0 &&
                                <Chip
                                    key={languageFilter}
                                    label={getLanguageString(languageFilter)}
                                    onDelete={() => handleLanguageFilterDelete()}
                                />
                            }
                        </Box>
                        <Box>
                            <Button
                                onClick={handleOpenFilterMenu}
                                ref={filterRef}
                                size="medium"
                                variant="text"
                                className={classes.buttonMenuRight}
                            >
                                <FilterIcon className={classes.IconOnButton} />
                                <FormattedMessage id="channel.PublicChannelDetailView.PublicSearchBar.filter" />
                                <ArrowDropDownIcon />
                            </Button>
                        </Box>

                        <Menu
                            anchorEl={filterRef.current}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            open={openFilterMenu}
                            onClose={handleCloseFilterMenu}
                            className={classes.menuFilter}
                        >
                            <Card className={classes.cardFilter}>

                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        value={languageFilter}
                                        onChange={changeLanugage}
                                        input={<OutlinedInput label={intl.formatMessage({ id: 'channel.PublicChannelDetailView.PublicSearchBar.languages' })} />}
                                    >
                                        {languages.map((language) => (
                                            <MenuItem
                                                key={language.id}
                                                value={language.id}
                                            >
                                                {language.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Card>
                        </Menu>
                    </CardActions>
                </Card>
            </Box>
        </div>
    );
};

export default PublicSearchBar;