import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, IconButton, Dialog, DialogTitle, DialogContent, SvgIcon, Typography, Button, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    X as XIcon,
    Video as VideoIcon,
    ChevronDown as ChevronDownIcon,
    Paperclip as PaperclipIcon
} from 'react-feather';
import type { Media } from 'src/types/media';
import { EntriesConfig } from "src/components/player-container";
import PlayerContainer from "./PlayerContainer";
import {
    KalturaPlayerProvider,
} from "src/kaltura-player";
import VideoPlayerDialogShare from './VideoPlayerDialogShare';
import parse from "html-react-parser";
import { FormattedMessage } from 'react-intl';

export interface VideoPlayerDialogMediumProps {
    isOpen?: boolean;
    onClose?: () => void;
    video?: Media;
    playerTime?: number;
    setPlayerTime?: any;
    channelViewId?: number;
    downloadAttachmentsLink?: string;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: 'rgba(0, 0, 51, 0.5)',
    },
    closebutton: {
        float: 'right',
        marginRight: '-0.75rem',
    },
    button: {
        display: 'flex',
        marginLeft: 'auto',
        alignSelf: 'flex-end',
        marginTop: '0.5rem'
    }
}));

const VideoPlayerDialogMedium: FC<VideoPlayerDialogMediumProps> = ({
    isOpen,
    onClose,
    video,
    playerTime,
    setPlayerTime,
    channelViewId,
    downloadAttachmentsLink
}) => {

    const classes = useStyles();
    const entriesConfig: EntriesConfig = {
        entryId: video.entry_id,
        alternateEntryId: ""
    };

    const [isMenuOpen, setOpenMenu] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleDownloadButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        toggleMenu();
    };

    const toggleMenu = (): void => {
        setOpenMenu(!isMenuOpen);
    };

    const downloadFile = (link: string) => {
        if (link) {
            const doc = document.createElement('a');
            doc.href = link;
            doc.download;
            doc.click();
        }
    }

    return (
        <Dialog
            onClose={onClose}
            open={isOpen}
            fullWidth={true}
            maxWidth="lg"
            className={classes.wrapper}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box>
                <DialogTitle>
                    <Box
                        mb={-2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            variant="h4"
                            color="textPrimary"
                        >
                            {video.title}
                        </Typography>
                        <IconButton className={classes.closebutton} onClick={onClose} size="large">
                            <SvgIcon fontSize="small">
                                <XIcon />
                            </SvgIcon>
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box mt={0} mb={4}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="left"
                    >
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            {video.description !== null && typeof (video.description) === 'string' &&
                                video.description !== "" && parse(video.description)}
                        </Typography>
                    </Box>
                    <KalturaPlayerProvider>
                        <PlayerContainer entriesConfig={entriesConfig} video={video} playerTime={playerTime} setPlayerTime={setPlayerTime} playerheight={450} />
                    </KalturaPlayerProvider>
                    <Box style={{ display: 'flex' }}>

                        <VideoPlayerDialogShare channelViewId={channelViewId} video={video} />
                        <Box className={classes.button}>
                            <Button
                                className={classes.button}
                                color="primary"
                                variant="contained"
                                disabled={(!downloadAttachmentsLink && !video.download_url)}
                                onClick={handleDownloadButtonClick}
                                endIcon={
                                    <SvgIcon fontSize="small" >
                                        <ChevronDownIcon />
                                    </SvgIcon>
                                }
                            >
                                <FormattedMessage id="VideoPlayer.VideoPlayerDialogMedium.download" />
                            </Button>
                        </Box>
                        <Menu open={isMenuOpen} onClose={toggleMenu}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            anchorEl={anchorEl}
                            style={{ borderRadius: '1px', border: '1px solid' }}
                        >
                            <MenuItem style={{ display: 'flex', paddingRight: '0.5rem' }} onClick={() => downloadFile(video.download_url)}>
                                <Button variant='text'
                                    href={video.download_url}
                                    disabled={!video.download_url}
                                    color='inherit'
                                    startIcon={
                                        <SvgIcon fontSize="small" >
                                            <VideoIcon />
                                        </SvgIcon>
                                    }>
                                    <FormattedMessage id="VideoPlayer.VideoPlayerDialogMedium.downloadVideo" />
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button variant='text'
                                    href={downloadAttachmentsLink}
                                    disabled={!downloadAttachmentsLink}
                                    color='inherit'
                                    startIcon={
                                        <SvgIcon fontSize="small" >
                                            <PaperclipIcon />
                                        </SvgIcon>
                                    }>
                                    <FormattedMessage id="VideoPlayer.VideoPlayerDialogMedium.downloadAttachment" />
                                </Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    );
};

export default VideoPlayerDialogMedium;

