import React, {
    useState,
    useRef
} from 'react';
import type { FC } from 'react';
import {
    Badge,
    Box,
    Button,
    IconButton,
    Popover,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
        padding: theme.spacing(2)
    }
}));

const Settings: FC = () => {
    const classes = useStyles();
    const ref = useRef<any>(null);

    return <>
        <Tooltip title={<FormattedMessage id="layouts.DashboardLayout.TopBar.Settings.configuration" />}>
            <Badge
                color="secondary"
            >
                <Route render={({ history }) => (
                    <IconButton
                        color="default"
                        onClick={() => { history.push('/app/account') }}
                        ref={ref}
                        size="large">
                        <SvgIcon fontSize="small">
                            <SettingsIcon />
                        </SvgIcon>
                    </IconButton>
                )} />
            </Badge>
        </Tooltip>
    </>;
}

export default Settings;