import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
    Menu,
    IconButton,
    SvgIcon,
    MenuItem,
    Box,
    Typography
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
    Globe as GlobeIcon,
} from 'react-feather';

import PropTypes from 'prop-types';
import useSettings from 'src/hooks/useSettings';

interface LanguageSelectionProps {
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    arrowButton: {
        marginLeft: '-0.5rem',
        marginTop: '-0.5rem'
    },
    primary: {
        color: theme.palette.primary.main
    }
}));

const LanguageSelection: FC<LanguageSelectionProps> = ({ className, ...rest }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const [languageSelected, setLanguageSelected] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const initializeLanguage = () => {
        let lang = localStorage.getItem('language');
        localStorage.removeItem('user')
        if (lang) {
            setLanguageSelected(lang)
        }
        else {
            let lang_browser = navigator.language;
            let lang_primary = lang_browser.split('-')[0];
            localStorage.setItem('language', lang_primary);
            setLanguageSelected(lang_primary)
        }
        window.dispatchEvent(new Event('userUpdated'));
        setLoading(false)
    };

    const handleSwitchLanguage = (new_language) => {
        setAnchorEl(null);
        setLanguageSelected(new_language);
        localStorage.setItem('language', new_language);
        window.dispatchEvent(new Event('userUpdated'));
    };

    const getLanguageString = (language: string): any => {
        if (language === "Español") {
            if (languageSelected === "es") return <Typography className={classes.primary}>{language}</Typography>
            else return <Typography>{language}</Typography>
        }
        else if (language == "Català") {
            if (languageSelected === "ca") return <Typography className={classes.primary}>{language}</Typography>
            else return <Typography>{language}</Typography>
        }
        else {
            if (languageSelected === "en") return <Typography className={classes.primary}>{language}</Typography>
            else return <Typography>{language}</Typography>
        }
    }

    useEffect(() => {
        initializeLanguage()
    }, []);

    if (loading) return null;
    else {
        return (
            <Box>
                <SvgIcon fontSize="small">
                    <GlobeIcon />
                </SvgIcon>

                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    className={classes.arrowButton}
                    onClick={handleClick}
                >
                    <ArrowDropDownIcon />
                </IconButton>
                <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleSwitchLanguage('ca')}>
                        <Typography component={'span'}>
                            {getLanguageString("Català")}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleSwitchLanguage('es')}>
                        <Typography component={'span'}>
                            {getLanguageString("Español")}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleSwitchLanguage('en')}>
                        <Typography component={'span'}>
                            {getLanguageString("English")}
                        </Typography>
                    </MenuItem>

                </Menu>
            </Box>
        );
    }
};

LanguageSelection.propTypes = {
    className: PropTypes.string,
};

export default LanguageSelection;