import React, {
    useRef
} from 'react';
import type { FC } from 'react';
import {
    Badge,
    IconButton,
    SvgIcon,
    Tooltip,
} from '@mui/material';
import {
    LogOut as LogOutIcon
} from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom'

const AlternativeLogout: FC = () => {
    const ref = useRef<any>(null);

    return <>
        <Tooltip title={<FormattedMessage id="layouts.DashboardLayout.TopBar.AlternativeLogout.exit" />}>
            <Badge
                color="secondary"
            >
                <Route render={({ history }) => (
                    <IconButton
                        color="default"
                        onClick={() => { history.push('/logout/sescam/') }}
                        ref={ref}
                        size="large">
                        <SvgIcon fontSize="small">
                            <LogOutIcon />
                        </SvgIcon>
                    </IconButton>
                )} />
            </Badge>
        </Tooltip>
    </>;
}

export default AlternativeLogout;