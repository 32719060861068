import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, LinearProgress, Typography, Grid, SvgIcon, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Search as SearchIcon,
} from 'react-feather';
import axios from 'axios';

import type { Media } from 'src/types/media';
import { getWSConfig } from 'src/views/utils/Utils';
import { Model } from 'src/types/model';
import { ChannelViewSection, ChannelViewSubsection, ChannelViewTab } from 'src/types/channelView';
import { Channel } from 'src/types/channel';
import PublicVideoBox from './PublicVideoBox';
import PublicModelBox from './PublicModelBox';
import Footer from './Footer';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileResource } from 'src/types/file_resource';
import { ExternalLinkResource } from 'src/types/external_link_resource';
import FileBox from './FileBox';
import ExternalLinkBox from './ExternalLinkBox';

interface PublicContentProps {
    currentTab: ChannelViewTab;
    selectedSectionOrSubSection?: ChannelViewSubsection | ChannelViewSection;
    channel: Channel;
    setSelectAll: any;
    VideoChecked: boolean;
    ModelsChecked: boolean;
    query: string;
    mode: string;
    setQuery: any;
    languageFilter: number;
    filesChecked: boolean;
    externalLinksChecked: boolean;
}
const useStyles = makeStyles((theme) => ({
    root: {
        '--ion-grid-width-xl': 1930,
    },
    linearProgress: {
        marginTop: '4rem',
        marginBottom: '4rem',
        marginLeft: '1rem',
        marginRight: '1rem',
    },
    boxContentNotFound: {
        paddingTop: '7rem',
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
    },
    noContentFoundIcon: {
        color: "darkgrey",
    },
}));

const PublicContent: FC<PublicContentProps> = ({
    currentTab,
    languageFilter,
    mode,
    query,
    setQuery,
    VideoChecked,
    ModelsChecked,
    selectedSectionOrSubSection,
    channel,
    filesChecked,
    externalLinksChecked,
    setSelectAll
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const config = getWSConfig();
    const [media, setMedia] = useState<Media[]>([]);
    const [models, setModels] = useState<Model[]>([]);
    const [existsVideos, setExistsVideos] = useState<boolean>(true);
    const [existsModels, setExistsModels] = useState<boolean>(true);
    let source;
    const [files, setFiles] = useState<FileResource[]>([]);
    const [externalLinks, setExternalLinks] = useState<ExternalLinkResource[]>([]);
    const [existsFiles, setExistsFiles] = useState<boolean>(true);
    const [existsExternalLinks, setExistsExternalLinks] = useState<boolean>(true);
    const [loadingVideos, setLoadingVideos] = useState<boolean>(true);
    const [loadingModels, setLoadingModels] = useState<boolean>(true);
    const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
    const [loadingExternalLinks, setLoadingExternalLinks] = useState<boolean>(true);

    const searchMediaSimple = (): void => {
        source = axios.CancelToken.source();
        let params = {};
        let config = { cancelToken: source.token };

        if (selectedSectionOrSubSection !== undefined) {
            setMedia([]);
            let url = '';

            if ('channel_view_sub_section_id' in selectedSectionOrSubSection) {
                url = '/channel_view_sub_section/1.0/public/list/content';
                params['channel_view_sub_section_id'] = selectedSectionOrSubSection.channel_view_sub_section_id;
            }
            else if ('channel_view_section_id' in selectedSectionOrSubSection) {
                if (selectedSectionOrSubSection.channel_view_section_id === 0) {
                    url = '/channel_view_tab/1.0/public/search/media';
                    params['channel_view_tab_id'] = currentTab.channel_view_tab_id;
                }
                else {
                    url = '/channel_view_section/1.0/public/list/content';
                    params['channel_view_section_id'] = selectedSectionOrSubSection.channel_view_section_id;
                }
            }

            if (VideoChecked === true) {
                params['videos'] = true;
                if (query !== "") {
                    params['search_term'] = query;
                }
                params['models'] = false;
                if (languageFilter !== undefined && languageFilter !== null && languageFilter !== 0) {
                    if (languageFilter === 1) {
                        params['language'] = "Spanish (Spain)";
                    }
                    else if (languageFilter === 2) {
                        params['language'] = "English";
                    }
                    else params['language'] = "Catalan";
                }
            }
            axios.post(process.env.REACT_APP_SERVER_URL + url, params, config)
                .then(function (response) {
                    if (response && response.data) {
                        let data: Media[] = response.data;

                        if (data.length >= 1) {
                            setExistsVideos(true);
                        }
                        else {
                            setExistsVideos(false);
                        }
                        setMedia(data);
                        setLoadingVideos(false);
                    }
                })
                .catch(function (error) {
                    setLoadingVideos(false);

                    if (axios.isCancel(error)) {
                        console.log('Request aborted', error.message);
                    }
                    else {
                        console.log("error loading videos from WS");

                        if (error && error.response && error.response.data) {
                            console.log(error.response.data);
                        }
                    }
                });
        }
    };

    const searchModelsSimple = (): void => {
        let modelsList: Model[] = [];

        if ("channel_view_sub_section_id" in selectedSectionOrSubSection) {
            for (let element of selectedSectionOrSubSection.channelViewSubSectionMode3DList) {
                modelsList.push(element.model_3D_entity);
            }
        }
        else if ("channel_view_section_id" in selectedSectionOrSubSection) {
            if (selectedSectionOrSubSection.channel_view_section_id === 0 && currentTab.channel_view_tab_id !== undefined) {
                for (let section of currentTab.channelViewSectionList) {
                    for (let subsection of section.channelViewSubSectionList) {
                        for (let element of subsection.channelViewSubSectionMode3DList) {
                            modelsList.push(element.model_3D_entity);
                        }
                    }
                }
            }
            else {
                for (let subsection of selectedSectionOrSubSection.channelViewSubSectionList) {
                    for (let element of subsection.channelViewSubSectionMode3DList) {
                        modelsList.push(element.model_3D_entity);
                    }
                }
            }
        }
        setModels(modelsList);
        setExistsModels(modelsList.length > 0);
        setLoadingModels(false);
    }

    const searchFilesSimple = (): void => {
        let filesList: FileResource[] = [];
        if ("channel_view_sub_section_id" in selectedSectionOrSubSection) {
            for (let element of selectedSectionOrSubSection.channelViewSubSectionFileResourceList) {
                filesList.push(element.fileResourceEntity);
            }
        }
        else if ("channel_view_section_id" in selectedSectionOrSubSection) {
            if (selectedSectionOrSubSection.channel_view_section_id === 0 && currentTab.channel_view_tab_id !== undefined) {
                for (let section of currentTab.channelViewSectionList) {
                    for (let subsection of section.channelViewSubSectionList) {
                        for (let element of subsection.channelViewSubSectionFileResourceList) {
                            filesList.push(element.fileResourceEntity);
                        }
                    }
                }
            }
            else {
                for (let subsection of selectedSectionOrSubSection.channelViewSubSectionList) {
                    for (let element of subsection.channelViewSubSectionFileResourceList) {
                        filesList.push(element.fileResourceEntity);
                    }
                }
            }
        }
        setFiles(filesList);
        setExistsFiles(filesList.length > 0);
        setLoadingFiles(false);
    }

    const searchExternalLinksSimple = (): void => {
        let externalLinksList: ExternalLinkResource[] = [];
        if ("channel_view_sub_section_id" in selectedSectionOrSubSection) {
            for (let element of selectedSectionOrSubSection.channelViewSubSectionExternalLinkResourceList) {
                externalLinksList.push(element.externalLinkResourceEntity);
            }
        }
        else if ("channel_view_section_id" in selectedSectionOrSubSection) {
            if (selectedSectionOrSubSection.channel_view_section_id === 0 && currentTab.channel_view_tab_id !== undefined) {
                for (let section of currentTab.channelViewSectionList) {
                    for (let subsection of section.channelViewSubSectionList) {
                        for (let element of subsection.channelViewSubSectionExternalLinkResourceList) {
                            externalLinksList.push(element.externalLinkResourceEntity);
                        }
                    }
                }
            }
            else {
                for (let subsection of selectedSectionOrSubSection.channelViewSubSectionList) {
                    for (let element of subsection.channelViewSubSectionExternalLinkResourceList) {
                        externalLinksList.push(element.externalLinkResourceEntity);
                    }
                }
            }
        }
        setExternalLinks(externalLinksList);
        setExistsExternalLinks(externalLinksList.length > 0);
        setLoadingExternalLinks(false);
    }

    const searchContent = (): any => {
        setLoadingVideos(true);
        setLoadingModels(true);
        setLoadingFiles(true);
        setLoadingExternalLinks(true);
        if (VideoChecked && ModelsChecked && filesChecked && externalLinksChecked) {
            searchMediaSimple();
            searchModelsSimple();
            searchFilesSimple();
            searchExternalLinksSimple();
            return () => {
                source.cancel("canceling call to WS");
            };
        }
        else if (VideoChecked && !ModelsChecked && !filesChecked && !externalLinksChecked) {
            searchMediaSimple();
            setExistsFiles(false);
            setExistsExternalLinks(false);
            setExistsModels(false);
            setLoadingModels(false);
            setLoadingFiles(false);
            setLoadingExternalLinks(false);
            return () => {
                source.cancel("canceling call to WS");
            };
        }
        else if (!VideoChecked && ModelsChecked && !filesChecked && !externalLinksChecked) {
            searchModelsSimple();
            setExistsFiles(false);
            setExistsExternalLinks(false);
            setExistsVideos(false);
            setLoadingVideos(false);
            setLoadingFiles(false);
            setLoadingExternalLinks(false);
        }
        else if (!VideoChecked && !ModelsChecked && filesChecked && !externalLinksChecked) {
            searchFilesSimple();
            setExistsVideos(false);
            setExistsExternalLinks(false);
            setExistsModels(false);
            setLoadingVideos(false);
            setLoadingModels(false);
            setLoadingExternalLinks(false);
        }
        else if (!VideoChecked && !ModelsChecked && !filesChecked && externalLinksChecked) {
            searchExternalLinksSimple();
            setExistsFiles(false);
            setExistsVideos(false);
            setExistsModels(false);
            setLoadingVideos(false);
            setLoadingModels(false);
            setLoadingFiles(false);
        }
    }

    const applyFiltersForTitle = (item: Model | FileResource | ExternalLinkResource, query: string): Boolean => {
        let matches = true;
        if (query) {
            const properties = ['title'];
            let containsQuery = false;
            let query_norm;
            let model_norm;
            query_norm = query.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            properties.forEach((property) => {
                if (item[property] !== undefined) {
                    model_norm = item[property].normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    if (model_norm.toLowerCase().includes(query_norm.toLowerCase())) {
                        containsQuery = true;
                    }
                }
            });
            if (!containsQuery) {
                matches = false;
            }
        }
        return matches;
    }

    const applyModelsFilters = (models: Model[], query: string): Model[] => {
        return models.filter((model) => {
            let matches = applyFiltersForTitle(model, query);
            return matches;
        });
    };

    const applyFilesFilters = (files: FileResource[], query: string): FileResource[] => {
        return files.filter((file) => {
            let matches = applyFiltersForTitle(file, query);
            return matches;
        });
    };

    const applyExternalLinksFilters = (externalLinks: ExternalLinkResource[], query: string): ExternalLinkResource[] => {
        return externalLinks.filter((externalLink) => {
            let matches = applyFiltersForTitle(externalLink, query);
            return matches;
        });
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (selectedSectionOrSubSection !== undefined) {
                if (selectedSectionOrSubSection.channel_view_section_id !== 0) {
                    setQuery("");
                }
            }
            searchContent();
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [VideoChecked, ModelsChecked, filesChecked, externalLinksChecked, selectedSectionOrSubSection, languageFilter, currentTab, query]);// eslint-disable-line react-hooks/exhaustive-deps

    const filteredModels = (models === null) ? [] : applyModelsFilters(models, query);
    const filteredFiles = (files === null) ? [] : applyFilesFilters(files, query);
    const filteredExternalLinks = (externalLinks === null) ? [] : applyExternalLinksFilters(externalLinks, query);

    if (loadingVideos || loadingFiles || loadingModels || loadingExternalLinks) {
        return (
            <div
                style={{ overflow: 'hidden', marginLeft: '0rem', marginTop: '2.5rem', paddingBottom: '0.5rem', display: "flex", justifyContent: "center" }}>
                <CircularProgress size={50} />
            </div>
        );
    }
    else {
        return (
            <div
                style={{ overflow: 'hidden', marginLeft: '0rem', marginTop: '2.5rem', paddingBottom: '0.5rem' }}>
                <Box>
                    <Box flexGrow={1} />
                    <Grid
                        container
                        spacing={3}
                    >
                        {VideoChecked &&
                            media.map((video) => {
                                return (
                                    <Grid
                                        item
                                        key={video.entry_id}
                                        xs={mode === 'grid' ? 12 : 12}
                                        sm={mode === 'grid' ? 12 : 6}
                                        md={mode === 'grid' ? 6 : 4}
                                        lg={mode === 'grid' ? 4 : 3}
                                        xl={mode === 'grid' ? 3 : 3}
                                    >
                                        <PublicVideoBox
                                            singlemedia={video}
                                            channelView={currentTab.channel_view_id}
                                            channel_id={channel.channel_id}
                                        />
                                    </Grid>
                                );
                            })}
                        {ModelsChecked && filteredModels.map((model) => {
                            return (
                                <Grid
                                    item
                                    key={model.model_3D_id}
                                    xs={mode === 'grid' ? 12 : 12}
                                    sm={mode === 'grid' ? 12 : 6}
                                    md={mode === 'grid' ? 6 : 4}
                                    lg={mode === 'grid' ? 4 : 3}
                                    xl={mode === 'grid' ? 3 : 3}
                                >
                                    <PublicModelBox
                                        singleModel={model}
                                        channel={channel}
                                    />
                                </Grid>
                            );
                        })}
                        {filesChecked && filteredFiles.map((file) => {
                            return (
                                <FileBox
                                    file={file}
                                    mode={mode}
                                />
                            );
                        })}
                        {externalLinksChecked && filteredExternalLinks.map((link) => {
                            return (
                                <ExternalLinkBox
                                    external_link={link}
                                    mode={mode}
                                />
                            );
                        })}
                    </Grid>

                    {!existsVideos && !existsModels && !existsFiles && !existsExternalLinks &&
                        < Box
                            justifyContent="center"
                            display="grid"
                            flexGrow={1}
                            paddingTop="7rem"
                        >
                            <Box
                                justifyContent="center"
                                display="grid"
                            >
                                <SvgIcon
                                    fontSize="large"
                                    className={classes.noContentFoundIcon}
                                >
                                    <SearchIcon />
                                </SvgIcon>
                            </Box>
                            <Box
                                justifyContent="center"
                                display="grid"
                            >
                                <Typography
                                    variant="h4"
                                    color="textPrimary"
                                >
                                    <FormattedMessage id="channel.PublicChannelDetailView.PublicContent.no_results_search" />
                                </Typography>
                            </Box>
                            <Box
                                justifyContent="center"
                                display="grid"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    <FormattedMessage id="channel.PublicChannelDetailView.PublicContent.try_again_search" />
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </div>
        );
    }
};

export default PublicContent;