import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Hidden, IconButton, Toolbar, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';

interface TopBarProps {
    className?: string;
    onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        backgroundColor: '#ffffff',
    },
    toolbar: {
        minHeight: 64
    }
}));

const TopBar: FC<TopBarProps> = ({
    className,
    onMobileNavOpen,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <AppBar
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton color="primary" onClick={onMobileNavOpen} size="large">
                        <SvgIcon fontSize="small">
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden lgDown>
                    <RouterLink to="/">
                        <Logo />
                    </RouterLink>
                </Hidden>
                <Box
                    ml={2}
                    flexGrow={1}
                />

                {/* <Settings /> */}
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
    onMobileNavOpen: () => { }
};

export default TopBar;
