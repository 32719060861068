import React from 'react';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import PeopleAltOutlinedIcon from '@mui/icons-material/Login';
import { useHistory } from 'react-router-dom';
import { Button, SvgIcon, AppBar, Toolbar, Hidden, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChannelView, ChannelViewInfoType } from 'src/types/channelView';
import { Channel } from 'src/types/channel';
import { FormattedMessage } from 'react-intl';
import LanguageSelection from 'src/views/auth/LanguageSelection';


interface PublicHeaderBarProps {
    channelViewInfo: ChannelViewInfoType;
    channel: Channel;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        header: {
            backgroundColor: theme.palette.background.default,
            boxShadow: 'none',
            zIndex: theme.zIndex.appBar,
            margin: 'auto',
            width: '100%',
        },
        signUpButton: {
            marginLeft: '1rem',
            width: '9rem',
            backgroundColor: '#1CA58A',
            color: theme.palette.background.default,
        },
        loginButton: {
            backgroundColor: theme.palette.background.default,
        },
        buttonsDiv: {
            marginLeft: "auto",
            marginRight: -12,
        },
        appBar: {
            backgroundColor: theme.palette.background.default
        },
        languageIcon: {
            backgroundColor: theme.palette.background.default,
            color: '#535961',
            marginTop: 16
        },
    }),
);

const PublicHeaderBar: FC<PublicHeaderBarProps> = ({ channelViewInfo, channel }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.header}>
                    {channelViewInfo && channelViewInfo.web_url ?
                        (
                            <a href={channelViewInfo.web_url} target="_blank">
                                <img
                                    style={{ width: '15rem' }}
                                    alt="Logo"
                                    src={channel.public_logo}
                                />
                            </a>
                        ) : (
                            <img
                                style={{ width: '15rem' }}
                                alt="Logo"
                                src={channel.public_logo}
                            />
                        )
                    }
                    <div className={classes.buttonsDiv}>
                        <a style={{ textDecoration: 'none' }} href="https://www.pro.digimevo.com/">
                            <Button
                                variant="contained"
                                className={classes.loginButton}
                                color="tertiary"
                                startIcon={
                                    <SvgIcon fontSize="small">
                                        <PeopleAltOutlinedIcon />
                                    </SvgIcon>
                                }
                            >
                                <FormattedMessage id="channel.PublicChannelDetailView.PublicHeaderBar.enter" />
                            </Button>
                        </a>
                        <a style={{ textDecoration: 'none' }} href="https://www.pro.digimevo.com/register">
                            <Button className={classes.signUpButton} variant="contained">
                                <FormattedMessage id="channel.PublicChannelDetailView.PublicHeaderBar.register" />
                            </Button>
                        </a>
                    </div>
                    <Box ml={5} className={classes.languageIcon}>
                        <LanguageSelection />
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default PublicHeaderBar;