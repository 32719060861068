import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Box, IconButton, Dialog, SvgIcon, Typography, Container, Button, CircularProgress, Menu, ListItemText, Link, ListItemIcon, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    X as XIcon,
    Share2 as ShareIcon,
    Paperclip as PaperClipIcon,
    ChevronDown as ChevronDownIcon,
    Video as VideoIcon
} from 'react-feather';
import { Media } from 'src/types/media';
import { KalturaPlayer, KalturaPlayerProvider } from 'src/kaltura-player';
import { useSnackbar } from 'notistack';
import { ChannelView } from 'src/types/channelView';
import PlayerContainer, { EntriesConfig } from 'src/views/VideoPlayer/PlayerContainer';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';
import { LoadingButton } from '@mui/lab';

interface MobileVideoPlayerModalProps {
    isOpen?: boolean;
    onClose?: () => void;
    media: Media;
    channelView: ChannelView;
    playerTime?: number;
    setPlayerTime?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    dialog: {
        zIndex: theme.zIndex.drawer
    },
    shareButton: {
        color: theme.palette.text.primary,
        '&.MuiButton-root': {
            border: '1px solid'
        }
    }
}));

const MobileVideoPlayerModal: FC<MobileVideoPlayerModalProps> = ({
    isOpen,
    onClose,
    media,
    channelView,
    playerTime,
    setPlayerTime
}) => {
    const classes = useStyles();

    const closeModal = (): void => {
        onClose()
    }
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const [attachmentLink, setAttachmentLink] = useState<string>()
    const [loadingAttachment, setLoadingAttachment] = useState<boolean>(true)
    const config = getWSConfig();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const copyURL = (): void => {
        if ((location.search).split('=')[1] != null) {
            navigator.clipboard.writeText("www." + (location.search).split('=')[1] + "/" + media.entry_id);
        }
        else navigator.clipboard.writeText(window.location.origin + "/channelview/" + channelView + "/" + media.entry_id);
        enqueueSnackbar(intl.formatMessage({ id: 'channel.PublicChannelDetailView.mobile.MobileVideoPlayerModal.url_copied' }), { variant: 'success' });
    }

    const getAttachmentLink = (): void => {
        let params = { media_id: media.entry_id }
        axios.post(process.env.REACT_APP_SERVER_URL + '/media/1.0/get/attachments', params, config)
            .then(function (response) {
                if (response && response.data) {
                    setAttachmentLink(response.data)
                }
            })
            .finally(() => {
                setLoadingAttachment(false)
            });
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const entriesConfig: EntriesConfig = {
        entryId: media.entry_id,
        alternateEntryId: ""
    };

    const getDisabled = () => {
        if (media.download_url || attachmentLink) return false
        return true
    }

    useEffect(() => {
        getAttachmentLink();
    }, []);

    return (
        <Dialog
            classes={{ paper: classes.dialog }}
            fullScreen
            open={isOpen}>
            <IconButton onClick={closeModal} sx={{ marginRight: 0, marginLeft: 'auto' }}>
                <XIcon />
            </IconButton>
            <Container sx={{ marginTop: 10 }}>
                <Typography sx={{ marginBottom: 2 }}
                    variant="h4"
                    color='textPrimary'>
                    {media.title}
                </Typography>
                {setPlayerTime &&
                    <KalturaPlayerProvider>
                        <PlayerContainer playerheight={300} entriesConfig={entriesConfig} video={media} setPlayerTime={setPlayerTime} playerTime={playerTime} />
                    </KalturaPlayerProvider>
                }
                {!setPlayerTime &&
                    <KalturaPlayerProvider>
                        <KalturaPlayer
                            entryId={media.entry_id}
                        />
                    </KalturaPlayerProvider>
                }
                <Typography sx={{ marginTop: '2rem' }}
                    variant="body2"
                    color='textPrimary'>
                    {media.description}
                </Typography>
                <Box mt={2} justifyContent="space-between" display="flex">
                    <Button
                        className={classes.shareButton}
                        variant="outlined"
                        onClick={copyURL}
                        startIcon={
                            <SvgIcon fontSize="small">
                                <ShareIcon />
                            </SvgIcon>
                        }
                    >
                        <FormattedMessage id="channel.PublicChannelDetailView.mobile.MobileVideoPlayerModal.share" />
                    </Button>
                    {
                        (loadingAttachment) &&
                        <LoadingButton loading variant="outlined">
                        </LoadingButton>

                    }
                    {
                        (!loadingAttachment) &&
                        <>
                            <Button
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClick}
                                disabled={getDisabled()}
                                endIcon={<ChevronDownIcon />}
                            >
                                <FormattedMessage id={"channel.PublicChannelDetailView.mobile.MobileVideoPlayerModal.download"}></FormattedMessage>
                            </Button>
                            <Menu
                                id="demo-customized-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <Link href={media.download_url} underline="none">
                                    <MenuItem
                                        href={media.download_url}
                                        disabled={(!media.download_url)}
                                        onClick={handleClose}
                                        disableRipple
                                    >
                                        <ListItemIcon>
                                            <VideoIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <FormattedMessage id={"channel.PublicChannelDetailView.mobile.MobileVideoPlayerModal.video"}></FormattedMessage>
                                        </ListItemText>
                                    </MenuItem>
                                </Link>
                                <Link href={attachmentLink} underline="none">
                                    <MenuItem
                                        onClick={handleClose}
                                        disabled={(attachmentLink == undefined)}
                                        disableRipple
                                    >
                                        <ListItemIcon>
                                            <PaperClipIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <FormattedMessage id={"channel.PublicChannelDetailView.mobile.MobileVideoPlayerModal.file"}></FormattedMessage>
                                        </ListItemText>
                                    </MenuItem>
                                </Link>

                            </Menu>
                        </>
                    }
                </Box>
            </Container>
        </Dialog>
    );
};

export default MobileVideoPlayerModal;