import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CardActionArea, CardMedia, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    PlayCircle as PlayCircleIcon,
    Lock as LockIcon,
    Video as VideoIcon,
} from 'react-feather';
import type { Media } from 'src/types/media';
import Label from 'src/components/Label';
import parse from "html-react-parser";
import { FormattedMessage, useIntl } from 'react-intl';

interface VideoBoxInfoProps {
    className?: string;
    singlemedia?: Media;
    setMediaToModal: any;
    setVideoPlayDialogOpen: any;
    checkVideoAccess: any;
    visibilityMode: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    titleMedia: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '0.5rem',
        height: '2.5rem',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    description: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '2rem',
    },
    image: {
        width: '100%',
        height: 'auto',
        aspectRatio: '16 / 9',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.dark,
    },
    playCircleIcon: {
        position: 'absolute',
        visibility: 'hidden',
        pointerEvents: 'none',
        top: "50%",
        left: "50%",
        transform: "Translate(-50%,-50%)",
        width: '2rem',
        color: 'white',
        cursor: 'pointer',
    },
    labelTime: {
        position: 'absolute',
        pointerEvents: 'none',
        top: "85%",
        left: "80%",
        transform: "Translate(-50%,-50%)",
        color: 'white',
        cursor: 'pointer',
        background: 'rgba(0, 0, 51, 0.87)',
    },
    divImage: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        '&:hover': {
            '& svg': {
                visibility: 'visible',
            },
        },
    },
    treeitems: {
        '& div': {
            '& div': {
                fontSize: '0.875rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.5rem',
            }
        }
    },
    firstTreeitem: {
        paddingLeft: '1rem',
        paddingBottom: '1.5rem',
        '& div': {
            '& div': {
                fontSize: '0.875rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.5rem',
            }
        }
    },
    divider: {
        marginTop: '0rem',
        marginLeft: '0rem',
        marginRight: '0rem',
    },
    labelVideoText: {
        marginLeft: '0.5rem',
        background: 'grey-300',
    },
    actionArea: {
        color: 'TextPrimary',
        '&:hover $focusHighlight': {
            opacity: 0.5,
            color: 'TextPrimary',
        },
    },
    focusHighlight: {},
    lockIcon: {
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        top: "40%",
        left: "50%",
        transform: "Translate(-50%,-40%)",
        width: '2rem',
        color: 'white',
    },
    buttonIcon: {
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        top: "70%",
        left: "50%",
        transform: "Translate(-50%,-50%)",
        height: '1.5rem',
        width: '2rem',
        color: 'white',
    },
    lockDiv: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        '&:hover': {
            '& svg': {
                visibility: 'visible',
            },
            '& button': {
                visibility: 'visible',
            }
        },
    },
    videoText: {
        overflow: 'hidden',
    },
    videoIcon: {
        position: 'relative',
        color: theme.palette.text.secondary,
        float: 'left',
    },
}));

const VideoBoxInfo: FC<VideoBoxInfoProps> = ({
    className,
    singlemedia,
    setMediaToModal,
    setVideoPlayDialogOpen,
    checkVideoAccess,
    visibilityMode,
    ...rest
}) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Box
            p={2}
            paddingBottom={3}
        >
            <div className={classes.divImage}>
                {visibilityMode !== "Password" &&
                    <div>
                        <CardActionArea
                            classes={{
                                root: classes.actionArea,
                                focusHighlight: classes.focusHighlight
                            }}
                            onClick={() => checkVideoAccess()}
                        >
                            <CardMedia
                                className={classes.image}
                                image={singlemedia.big_thumbnailUrl}
                            >
                            </CardMedia>
                        </CardActionArea>

                        <SvgIcon className={classes.playCircleIcon}
                            fontSize="large"
                            color="action"
                        >
                            <PlayCircleIcon />
                        </SvgIcon>
                    </div>
                }
                {visibilityMode === "Password" &&
                    <div className={classes.lockDiv}>
                        <CardActionArea
                            classes={{
                                root: classes.actionArea,
                                focusHighlight: classes.focusHighlight
                            }}
                            onClick={() => checkVideoAccess()}
                        >
                            <CardMedia
                                className={classes.image}
                                image={singlemedia.big_thumbnailUrl}
                            >
                            </CardMedia>
                        </CardActionArea>
                        <SvgIcon className={classes.lockIcon}
                            fontSize="large"
                            color="action"
                        >
                            <LockIcon />
                        </SvgIcon>
                        <Button className={classes.buttonIcon}
                            variant='outlined'
                            color='inherit'
                            size='small'
                        >
                            <FormattedMessage id="channel.PublicChannelDetailView.PublicVideoBoxInfo.access" />
                        </Button>
                    </div>
                }
                <Label
                    className={classes.labelTime}
                >
                    <Typography>
                        {singlemedia.duration}
                    </Typography>
                </Label>
            </div>
            <Box
                display={"flex"}
            >
                <Box p={1.5} pl={0}>
                    <SvgIcon
                        fontSize="small"
                        className={classes.videoIcon}
                    >
                        <VideoIcon />
                    </SvgIcon>
                </Box>
                <Box pt={1.5}>
                    <Typography
                        color="textSecondary"
                        variant="h6"
                        className={classes.videoText}
                    >
                        {"Video"}
                    </Typography>
                </Box>
            </Box>

            <Box>
                <Typography
                    variant="h6"
                    color="textPrimary"
                    className={classes.titleMedia}
                    onClick={() => checkVideoAccess()}
                >
                    {singlemedia.title}
                </Typography>
            </Box>
            <Box>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    className={classes.description}
                >
                    {singlemedia.description !== null && typeof (singlemedia.description) === 'string' &&
                        singlemedia.description !== "" && parse(singlemedia.description)}
                </Typography>
            </Box>
        </Box >
    );


};

VideoBoxInfo.propTypes = {
    className: PropTypes.string,
};
export default VideoBoxInfo;
