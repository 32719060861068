import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Tab, Tabs, Divider, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChannelViewInfoType, ChannelViewTab } from 'src/types/channelView';
import { Channel } from 'src/types/channel';
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';
import { alertStatus } from 'src/types/alertStatus';
import PublicSearchBar from './PublicSearchBar';
import PublicLive from './PublicLive';
import PublicInnerNavBar from './PublicInnerNavBar';
import PublicChannelViewInfo from './PublicChannelViewInfo';
interface PublicChannelViewTabsProps {
    channelViewTabList: ChannelViewTab[];
    channel: Channel;
    liveEntryId: string;
    channelViewInfo: ChannelViewInfoType;
}

const useStyles = makeStyles(() => ({
    root: {},
    tab: {
        marginLeft: '0rem',
    },
    live: {
        marginLeft: 'auto',
    },
    divider: {
        marginTop: '0rem',
        marginLeft: '0rem',
        marginRight: '0rem',
        zIndex: 1
    },
    rightAlign: {
        marginLeft: 'auto',
        margin: 0,
    },
    alert: {
        marginTop: "1.5rem"
    },
}));

const PublicChannelViewTabs: FC<PublicChannelViewTabsProps> = ({ channelViewTabList, channel, liveEntryId, channelViewInfo }) => {
    const [currentTab, setCurrentTab] = useState<ChannelViewTab>(channelViewTabList[0]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [VideoChecked, setVideoChecked] = useState<boolean>(true);
    const [ModelsChecked, setModelsChecked] = useState<boolean>(true);
    const classes = useStyles();
    const [languageFilter, setLanguageFilter] = useState<number>(0);
    const [filesChecked, setFilesChecked] = useState<boolean>(true);
    const [externalLinksChecked, setExternalLinksChecked] = useState<boolean>(true);

    const handleTabsChange = (event: ChangeEvent<{}>, tab_name: string): void => {
        setCurrentTab(channelViewTabList.find(tab => tab.name === tab_name));
        setSelectAll(true);
    };

    useEffect(() => {
        setCurrentTab(channelViewTabList[0]);
        setQuery("");
        setSelectAll(true);
    }, [channel]);

    return (
        <div style={{ top: '5.5rem', marginRight: '2rem', marginLeft: '2rem' }}>
            <div style={{ top: '5.5rem', width: "100%" }}>
                <Tabs
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    value={currentTab.name}
                    variant="scrollable"
                    textColor="primary"
                    indicatorColor="primary"
                >
                    {channelViewTabList && channelViewTabList.map((tab) => (
                        (tab.isVisibleInPublicChannelView !== false) && (
                            tab.sequence !== 900 && tab.sequence !== 901 &&
                            <Tab
                                key={tab.name}
                                label={tab.name}
                                value={tab.name}
                                className={classes.tab}
                            />
                            || tab.sequence === 900 &&
                            <Tab
                                key={tab.name}
                                label={tab.name}
                                value={tab.name}
                                disabled={(liveEntryId === "" || liveEntryId === undefined || liveEntryId === null)}
                                className={classes.rightAlign}
                            />
                            || tab.sequence === 901 &&
                            <Tab
                                key={tab.name}
                                label={tab.name}
                                value={tab.name}
                                className={classes.tab}
                            />
                        )
                    ))}
                </Tabs>
            </div>
            <Divider className={classes.divider} />
            {currentTab.sequence !== 900 && currentTab.sequence !== 901 && (currentTab.isVisibleInPublicChannelView !== false) &&
                <PublicSearchBar
                    setSelectAll={setSelectAll}
                    setVideoChecked={setVideoChecked}
                    setModelsChecked={setModelsChecked}
                    query={query}
                    setQuery={setQuery}
                    languageFilter={languageFilter}
                    setLanguageFilter={setLanguageFilter}
                    setFilesChecked={setFilesChecked}
                    setExternalLinksChecked={setExternalLinksChecked}
                    currentTab={currentTab}
                />
            }
            {currentTab.sequence !== 900 && currentTab.sequence !== 901 && (currentTab.isVisibleInPublicChannelView !== false) &&
                <PublicInnerNavBar
                    channelViewSectionList={currentTab.channelViewSectionList}
                    currentTab={currentTab}
                    channel={channel}
                    setSelectAll={setSelectAll}
                    selectAll={selectAll}
                    VideoChecked={VideoChecked}
                    ModelsChecked={ModelsChecked}
                    query={query}
                    setQuery={setQuery}
                    languageFilter={languageFilter}
                    setLanguageFilter={setLanguageFilter}
                    filesChecked={filesChecked}
                    externalLinksChecked={externalLinksChecked}
                />
            }
            {currentTab.sequence === 900 && (currentTab.isVisibleInPublicChannelView !== false) &&
                <PublicLive
                    liveEntryId={liveEntryId}
                />
            }
            {currentTab.sequence === 901 && channelViewInfo && (currentTab.isVisibleInPublicChannelView !== false) &&
                <PublicChannelViewInfo
                    channel={channel}
                    channelViewInfo={channelViewInfo}
                />
            }
        </div>
    );
};

export default PublicChannelViewTabs;