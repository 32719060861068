import React from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
    Search as SearchIcon,
} from 'react-feather';
import { Box, Input } from '@mui/material';
import { useIntl } from 'react-intl';

interface MobileSearchBarProps {
    setQuery: any
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
        },
        iconButton: {
            desplay: 'flex',
            flexDirection: 'column'
        },
        header: {
            marginTop: 'auto',
            marginBottom: 'auto'
        },
        queryField: {
            width: '100%',
            marginLeft: theme.spacing(2),
        },
    }),
);

const MobileSearchBar: FC<MobileSearchBarProps> = ({ setQuery }) => {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();

    const handleChange = (event): void => {
        const { name, value } = event.target
        setQuery(value)
    }

    return (
        <Box
            display="flex"
            alignItems="center"
        >
            <SearchIcon />
            <Input
                onChange={handleChange}
                disableUnderline
                fullWidth
                className={classes.queryField}
                placeholder={intl.formatMessage({ id: 'channel.PublicChannelDetailView.mobile.MobileSearchBar.search' })}
            />
        </Box>
    );
}

export default MobileSearchBar;