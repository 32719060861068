import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Box, Typography, Stack, Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import type { Media } from 'src/types/media';
import { getWSConfig } from 'src/views/utils/Utils';
import { ChannelView, ChannelViewSection } from 'src/types/channelView';
import { Model } from 'src/types/model';
import MobilePublicVideoBox from './MobilePublicVideoBox';
import MobileSectionDrawer from './MobileSectionDrawer';
import { useIntl } from 'react-intl';
import MobileSubsectionDrawer from './MobileSubsectionDrawer';
import { FileResource } from 'src/types/file_resource';
import { ExternalLinkResource } from 'src/types/external_link_resource';
import MobileExternalLinkBox from './MobileExternalLinkBox';
import { Channel } from 'src/types/channel';
import MobileFileBox from './MobileFileBox';
import MobilePublicModelBox from './MobilePublicModelBox';

interface MobileTabContentProps {
    section: ChannelViewSection;
    player_uiConfid: string
    query: string;
    channelView: ChannelView;
    channel: Channel;
    tabName: string;
}
const useStyles = makeStyles((theme) => ({
    stack: {
        overflowX: 'scroll',
        marginTop: '1rem',
        paddingBottom: '1rem'
    },
    seeAll: {
        color: theme.palette.text.secondary,
        marginLeft: 'auto',
        paddingTop: '4px',
        paddingRight: 0
    }
}));

const MobileSectionContent: FC<MobileTabContentProps> = ({ section, player_uiConfid, query, channelView, channel, tabName }) => {
    const classes = useStyles();
    const config = getWSConfig();
    const [media, setMedia] = useState<Media[]>();
    const [models, setModels] = useState<Model[]>([]);
    const [files, setFiles] = useState<FileResource[]>([]);
    const [externalLinks, setExternalLinks] = useState<ExternalLinkResource[]>([]);
    const [loadingVideos, setLoadingVideos] = useState<boolean>(true);
    const [loadingModels, setLoadingModels] = useState<boolean>(true);
    const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
    const [loadingExternalLinks, setLoadingExternalLinks] = useState<boolean>(true);
    const intl = useIntl();
    const [isSubsectionDrawerOpen, setSubsectionDrawerOpen] = useState<boolean>(false)
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

    const getSectionContent = (): void => {
        let params = {};
        params["channel_view_section_id"] = section.channel_view_section_id
        params["videos"] = true
        params["models"] = false
        axios.post(process.env.REACT_APP_SERVER_URL + '/channel_view_section/1.0/public/list/content', params, config)
            .then(function (response) {
                let data: Media[] = response.data;
                setMedia(data)
                setLoadingVideos(false)
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    const searchModelsSimple = (): void => {
        let modelsList: Model[] = [];
        for (let subsection of section.channelViewSubSectionList) {
            for (let element of subsection.channelViewSubSectionMode3DList) {
                modelsList.push(element.model_3D_entity);
            }
        }
        setModels(modelsList);
        setLoadingModels(false);
    }

    const searchFilesSimple = (): void => {
        let filesList: FileResource[] = [];
        for (let subsection of section.channelViewSubSectionList) {
            for (let element of subsection.channelViewSubSectionFileResourceList) {
                filesList.push(element.fileResourceEntity);
            }
        }
        setFiles(filesList);
        setLoadingFiles(false);
    };

    const searchExternalLinksSimple = (): void => {
        let externalLinksList: ExternalLinkResource[] = [];
        for (let subsection of section.channelViewSubSectionList) {
            for (let element of subsection.channelViewSubSectionExternalLinkResourceList) {
                externalLinksList.push(element.externalLinkResourceEntity);
            }
        }
        setExternalLinks(externalLinksList);
        setLoadingExternalLinks(false);
    }

    const searchContent = (): any => {
        setLoadingVideos(true);
        setLoadingModels(true);
        setLoadingFiles(true);
        setLoadingExternalLinks(true);

        getSectionContent();
        searchModelsSimple();
        searchFilesSimple();
        searchExternalLinksSimple();
    }

    useEffect(() => {
        searchContent()
    }, [section, query]);// eslint-disable-line react-hooks/exhaustive-deps

    const openSubsectionDrawer = (): void => {
        if (section.channelViewSubSectionList.length == 1) {
            if (section.channelViewSubSectionList[0].name === "Default") setSubsectionDrawerOpen(true)
            else setDrawerOpen(true)
        } else setDrawerOpen(true)
    }
    const closeSubsectionDrawer = (): void => {
        if (section.channelViewSubSectionList.length == 1) {
            if (section.channelViewSubSectionList[0].name == "Default") setSubsectionDrawerOpen(false)
            else setDrawerOpen(false)
        } else setDrawerOpen(false)
    }

    const applyFiltersForTitle = (item: Media | Model | FileResource | ExternalLinkResource, query: string): Boolean => {
        let matches = true;
        if (query) {
            const properties = ['title'];
            let containsQuery = false;
            let query_norm;
            let model_norm;
            query_norm = query.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            properties.forEach((property) => {
                if (item[property] !== undefined) {
                    model_norm = item[property].normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    if (model_norm.toLowerCase().includes(query_norm.toLowerCase())) {
                        containsQuery = true;
                    }
                }
            });
            if (!containsQuery) {
                matches = false;
            }
        }
        return matches;
    };
    const applyMediasFilters = (medias: Media[], query: string): Media[] => {
        return medias.filter((media) => {
            let matches = true;
            if (query) {
                const properties = ['title'];
                let containsQuery = false;
                let query_norm;
                let media_norm;
                query_norm = query.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                properties.forEach((property) => {
                    if (media[property] !== undefined) {
                        media_norm = media[property].normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                        if (media_norm.toLowerCase().includes(query_norm.toLowerCase())) {
                            containsQuery = true;
                        }
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
            }
            return matches;
        });
    };

    const applyModelsFilters = (models: Model[], query: string): Model[] => {
        return models.filter((model) => {
            let matches = applyFiltersForTitle(model, query);
            return matches;
        });
    };
    const applyFilesFilters = (files: FileResource[], query: string): FileResource[] => {
        return files.filter((file) => {
            let matches = applyFiltersForTitle(file, query);
            return matches;
        });
    };
    const applyExternalLinksFilters = (externalLinks: ExternalLinkResource[], query: string): ExternalLinkResource[] => {
        return externalLinks.filter((externalLink) => {
            let matches = applyFiltersForTitle(externalLink, query);
            return matches;
        });
    };

    const filteredMedias = (media === undefined) ? [] : applyMediasFilters(media, query);
    const filteredModels = (models === null) ? [] : applyModelsFilters(models, query);
    const filteredFiles = (files === null) ? [] : applyFilesFilters(files, query);
    const filteredExternalLinks = (externalLinks === null) ? [] : applyExternalLinksFilters(externalLinks, query);

    if (loadingVideos || loadingFiles || loadingModels || loadingExternalLinks) {
        return (
            <div style={{ overflow: 'hidden', marginLeft: '0rem', marginTop: '2.5rem', paddingBottom: '0.5rem', display: "flex", justifyContent: "center" }}>
                <CircularProgress size={50} />
            </div>
        );
    } else {
        return (
            <Box>
                <Box display="flex">
                    <Typography variant="h5" color="textPrimary">
                        {section.name}
                    </Typography>
                    <Button className={classes.seeAll} size="medium" onClick={() => openSubsectionDrawer()}>
                        {intl.formatMessage({ id: 'channel.PublicChannelDetailView.mobile.MobileSectionContent.see_all' })}
                    </Button>
                </Box>
                <Stack
                    direction="row"
                    spacing={2}
                    className={classes.stack}
                >
                    {filteredMedias.map((video, index) => {
                        return (
                            <MobilePublicVideoBox key={index} channelView={channelView}
                                singleMedia={video} player_uiConfid={player_uiConfid}
                            />
                        );
                    })}

                    {/* Models rendering */}
                    {filteredModels.map((model, index) => {
                        return (
                            <MobilePublicModelBox key={index} singleModel={model} channel={channel} />
                        );
                    })}

                    {/* Files rendering */}
                    {filteredFiles.map((file, index) => {
                        return (
                            <MobileFileBox key={index} file={file} />
                        );
                    })}

                    {/* Links rendering */}
                    {filteredExternalLinks.map((link, index) => {
                        return (
                            <MobileExternalLinkBox key={index} external_link={link} />
                        );
                    })}
                </Stack>
                {isDrawerOpen && <MobileSectionDrawer tabName={tabName} channelView={channelView} channel={channel} isOpen={isDrawerOpen} onClose={closeSubsectionDrawer} player_uiConfid={player_uiConfid} section={section} ></MobileSectionDrawer>}
                <MobileSubsectionDrawer isOpen={isSubsectionDrawerOpen} media={media} onClose={closeSubsectionDrawer} section={section} channelView={channelView} beforeName={tabName} channel={channel} models={models} files={files} externalLinks={externalLinks}></MobileSubsectionDrawer>
            </Box>
        );
    }
};

export default MobileSectionContent;